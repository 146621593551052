import { InlineVideo } from "./components/InlineVideo";
import Link from "next/link";

export default function Footer({
  src = "/videos/SnoopsteinAndDogg-web.mp4",
  text = "Your golden ticket to riches and possibly a cheese shop!",
  subtext = "",
}) {
  return (
    <footer className="bg-black relative footer">
      <InlineVideo
        src={src}
        alt="Footer video"
        className="w-full h-full object-cover aspect-square md:aspect-auto footer-video z-0"
      />
      <div className="z-[1] footer-text top-0 left-0 w-full h-full text-white p-4 text-left flex py-10 items-start md:items-center px-gutter gap-6 font-black leading-[0.8] bg-[rgba(0,0,0,0.25)]">
        <div className="w-[1400px] mx-auto max-w-full stack gap-6">
          <span className="text-footer">{text}</span>
          <span className="block mt-4 text-2xl">{subtext}</span>
        </div>
      </div>
      <div className="flex justify-center gap-4 py-4 gutter relative z-40">
        <span className="text-white">
          © {new Date().getFullYear()} Thinknado
        </span>
        <span className="text-white">|</span>
        <Link
          href="/privacy-policy"
          className="link text-white hover:text-pink"
        >
          Privacy Policy
        </Link>
        <span className="text-white">|</span>
        <Link
          href="/terms-of-service"
          className="link text-white hover:text-pink"
        >
          Terms of Service
        </Link>
      </div>
    </footer>
  );
}
