"use client";

import { useState } from "react";
// import { Pause, Play } from "react-feather";
import { If } from "react-haiku";
import { Play, Pause } from "lucide-react";
import { InlineVideo } from "./components/InlineVideo";

export default function VideoInline({ src = "" }) {
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <button
      onClick={() => setIsPlaying(!isPlaying)}
      className="w-full h-full relative"
    >
      <If isTrue={!isPlaying}>
        {/* <video
          className="w-full h-full object-cover"
          controls={false}
          autoPlay
          playsInline
          muted
          loop
        >
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        <InlineVideo
          src={src}
          alt="Footer video"
          className="w-full h-full object-cover"
        />

        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full bg-black bg-opacity-40" />

        <span className="absolute bottom-14 right-4 z-[51] text-white bg-purple rounded-full p-4">
          <Play />
        </span>
      </If>

      <If isTrue={isPlaying}>
        <video className="w-full h-full object-cover" controls autoPlay loop>
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <span className="absolute bottom-14 right-4 z-[51] text-white bg-purple rounded-full p-4">
          <Pause />
        </span>
      </If>
    </button>
  );
}
