interface AppData {
  whatIfImages: {
    numberOfCards: number;
    descriptions: {
      [key: string]: string;
    };
  };
  whatIfText: string[];
  waitWhy: string[];
}

export const appData: AppData = {
  //Black Cards
  whatIfImages: {
    numberOfCards: 150,
    descriptions: {
      "02": "People in a cafeteria",
      "03": "Post-its on a wall",
      "04": "Kid screaming in a mic",
      "05": "Glaciers in the ocean",
      "06": "Shipping dock with crains",
      "07": "Bycicle racers",
      "08": "Old cameras",
      "09": "Picking peaches",
      10: "Kids creating art",
      11: "Film roll",
      12: "Ballon animals",
      13: "Person using VR",
      14: "Cleaning spray",
      15: "Surfers in the ocean",
      16: "Tomato",
      17: "An electric guitar with an amp and petals",
      18: "Gel capsules",
      19: "Medical vials being filled",
      20: "large wave",
      21: "Vast library shelves",
      22: "gargoyle",
      23: "neon art sign",
      24: "desert",
      25: "camp fire",
      26: "dilapidated water park",
      27: "cheering crowd",
      28: "landfill",
      29: "Hazy city skyline",
      30: "Smoke stack",
      31: "arial view of a city",
      32: "moon",
      33: "mountains",
      34: "spider",
      35: "rabbit",
      36: "woman",
      37: "just married couple",
      38: "gorilla",
      39: "pug wearing a yellow hat",
      40: "surfer riding a wave",
      41: "boxers",
      42: "birthday cake",
      43: "woman meditating",
      44: "grafiiti",
      45: "sign on a floor",
      46: "absract geometric art",
      47: "ballet dancers",
      48: "art gallery",
      49: "sewing machine",
      50: "a fortune cookie",
      51: "friends drinking coffee",
      "52": "Street musicians playing",
      "53": "Colorful hot air balloons",
      "54": "Vintage typewriter",
      "55": "Butterflies in a garden",
      "56": "A cozy book nook",
      "57": "Sunset over a vineyard",
      "58": "Ice cream truck",
      "59": "Potter shaping clay",
      "60": "Rooftop garden",
      "61": "Misty forest path",
      "62": "Abandoned train tracks",
      "63": "Kite flying in the sky",
      "64": "Handmade jewelry display",
      "65": "Picnic in the park",
      "66": "Underwater coral reef",
      "67": "Vintage record player",
      "68": "Farmer's market",
      "69": "Old lighthouse",
      "70": "Raindrops on a window",
      "71": "Cobbled street in an old town",
      "72": "Board game night",
      "73": "Sandcastle on a beach",
      "74": "Star gazing",
      "75": "Snow-covered cabin",
      "76": "Paper lanterns at night",
      "77": "Artist painting a mural",
      "78": "Skateboarder in a skate park",
      "79": "Pile of autumn leaves",
      "80": "Blooming cherry blossom tree",
      "81": "Abandoned amusement park",
      "82": "Foggy mountain range",
      "83": "Yoga class in the park",
      "84": "Retro diner",
      "85": "Sunflower field",
      "86": "Starry night sky",
      "87": "Majestic waterfall",
      "88": "Vintage car show",
      "89": "Sushi on a conveyor belt",
      "90": "Lantern festival",
      "91": "Puppy in a basket",
      "92": "Chalk art on a sidewalk",
      "93": "Steaming cup of tea",
      "94": "Night market",
      "95": "A field of lavender",
      "96": "Bookstore shelves",
      "97": "Hot air balloon festival",
      "98": "A painter's palette",
      "99": "Busy bee on a flower",
      "100": "Jazz band performance",
      "101": "A cozy winter cabin",
      "102": "Night view of a bridge",
      "103": "Children playing in a sprinkler",
      "104": "Old steam train",
      "105": "Pasta dish with fresh basil",
      "106": "Hiker overlooking a valley",
      "107": "City skyline at dusk",
      "108": "Classic diner interior",
      "109": "Rock climber on a cliff",
      "110": "Freshly baked bread",
      "111": "Full moon over the sea",
      "112": "Sculptor working on a statue",
      "113": "Vintage clock tower",
      "114": "Butterfly landing on a flower",
      "115": "Busy farmer's market",
      "116": "Silhouette of a dancer",
      "117": "Wild horses running",
      "118": "Candlelit dinner table",
      "119": "Snowy mountain peak",
      "120": "Vibrant coral underwater",
      "121": "Aerial view of a winding road",
      "122": "Sunrise over a hot air balloon",
      "123": "Antique map",
      "124": "Rainforest canopy",
      "125": "Street artist painting",
      "126": "Lightning storm over the city",
      "127": "Carved pumpkin lanterns",
      "128": "Stained glass window",
      "129": "Wheat field at sunset",
      "130": "Colorful bird on a branch",
      "131": "Ice skater on a frozen pond",
      "132": "Fireworks over a cityscape",
      "133": "Zen garden",
      "134": "Old bookstore",
      "135": "Astronaut in space",
      "136": "Venice canal",
      "137": "Desert oasis",
      "138": "Vintage compass and map",
      "139": "Ballet performance on stage",
      "140": "Gourmet chocolate dessert",
      "141": "Sunset surfing",
      "142": "Lush vineyard",
      "143": "Festival of lights",
      "144": "Wind turbines at dawn",
      "145": "Pottery wheel with clay pot",
      "146": "Open road in the countryside",
      "147": "Tropical beach paradise",
      "148": "Old wooden sailing ship",
      "149": "Mosaic artwork",
      "150": "Illuminated tent under starry sky",
      "151": "Ancient ruins at sunset",
    },
  },
  //White Cards
  whatIfText: [
    "Global",
    "Woke",
    "Jolly",
    "Sneaky",
    "Deranged",
    "Fuzzy",
    "Fancy",
    "Sudden",
    "Lonely",
    "Lying",
    "Alert",
    "Awful",
    "Berserk",
    "Sloppy",
    "Rotten",
    "Boiling",
    "Awesome",
    "Absurd",
    "Lying",
    "Moist",
    "Faulty",
    "Open",
    "Righteous",
    "Cautious",
    "Alive",
    "Rabid",
    "Severe",
    "Mundane",
    "Narrow",
    "Fast",
    "Hard",
    "Yummy",
    "Aggressive",
    "Short",
    "Addicted",
    "Boring",
    "Flat",
    "Wrong",
    "Ubiquitous",
    "Childlike",
    "Unlikely",
    "Courageous",
    "Vulgar",
    "Endurable",
    "Paranoid",
    "Huge",
    "Glorious",
    "Erratic",
    "Inspired",
    "Wistful",
    "Brave",
    "Calm",
    "Delightful",
    "Eager",
    "Faithful",
    "Gentle",
    "Happy",
    "Inventive",
    "Joyful",
    "Kind",
    "Lucky",
    "Mysterious",
    "Naughty",
    "Obedient",
    "Proud",
    "Quiet",
    "Relieved",
    "Silly",
    "Thankful",
    "Upset",
    "Vivacious",
    "Witty",
    "Exotic",
    "Youthful",
    "Zealous",
    "Angry",
    "Blue",
    "Clumsy",
    "Defeated",
    "Embarrassed",
    "Fierce",
    "Grumpy",
    "Helpless",
    "Itchy",
    "Jealous",
    "Lazy",
    "Misty",
    "Nervous",
    "Old",
    "Powerful",
    "Quirky",
    "Rusty",
    "Scary",
    "Thoughtful",
    "Uptight",
    "Victorious",
    "Worried",
    "Xenophobic",
    "Youthful",
    "Zany",
    "Amused",
    "Bewildered",
    "Crazy",
    "Dizzy",
    "Energetic",
    "Foolish",
    "Greedy",
    "Hungry",
    "Irritated",
    "Jumpy",
    "Keen",
    "Lame",
    "Mighty",
    "Nasty",
    "Obnoxious",
    "Puzzled",
    "Quaint",
    "Robust",
    "Sour",
    "Tense",
    "Unusual",
    "Vengeful",
    "Weary",
    "Exhausted",
    "Yearning",
    "Zealous",
    "Animated",
    "Bashful",
    "Cheerful",
    "Determined",
    "Enthusiastic",
    "Frisky",
    "Generous",
    "Hilarious",
    "Independent",
    "Joyous",
    "Kindhearted",
    "Lively",
    "Merciful",
    "Notable",
    "Optimistic",
    "Peaceful",
    "Realistic",
    "Spectacular",
    "Thrifty",
    "Unique",
    "Valiant",
    "Warmhearted",
    "Xenial",
    "Youthful",
    "Zestful",
    "Accomplished",
    "Brilliant",
    "Competent",
    "Diligent",
    "Efficient",
    "Flexible",
    "Gracious",
    "Honest",
    "Innovative",
    "Jovial",
    "Knowledgeable",
    "Logical",
    "Modest",
    "Noteworthy",
    "Organized",
    "Perceptive",
    "Qualified",
    "Reliable",
    "Sophisticated",
    "Transparent",
    "Understanding",
    "Versatile",
    "Wise",
    "eXtraordinary",
    "Yearning",
    "Zealous",
    "Affectionate",
    "Blissful",
    "Compassionate",
    "Devoted",
    "Empathetic",
    "Forgiving",
    "Grateful",
    "Hopeful",
    "Inspiring",
    "Just",
    "Kindred",
    "Loving",
    "Mindful",
    "Nurturing",
    "Observant",
    "Patient",
    "Quaint",
    "Respectful",
    "Serene",
    "Tranquil",
    "Unwavering",
    "Vibrant",
    "Warm",
    "eXuberant",
    "Yielding",
    "Zealous",
  ],
  //Colored Cards
  waitWhy: [
    "Hope",
    "Global",
    "Surprise",
    "Fun",
    "Reality",
    "Fish",
    "Bees",
    "Extinction",
    "Work",
    "Health",
    "Economy",
    "Fashion",
    "School",
    "Politics",
    "Community",
    "Justice",
    "Family",
    "Safety",
    "Time",
    "Sports",
    "Integrity",
    "Social Media",
    "Aging",
    "Technology",
    "Science",
    "Peace",
    "Culture",
    "Art",
    "Music",
    "Agriculture",
    "Space",
    "Sex",
    "Gender",
    "Violence",
    "Waste",
    "Lonliness",
    "Morality",
    "Evil",
    "War",
    "Racism",
    "Food",
    "Water",
    "Transportation",
    "Housing",
    "Money",
    "Fear",
    "Climate",
    "Travel",
    "Religion",
    "Entertainment",
  ],
};
