export default function Quote({ flip = false }) {
  if (!flip) {
    return (
      <svg
        className="w-full"
        viewBox="0 0 236 190"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_213_37)">
          <path
            d="M0.000488281 99.9152C0.000488281 67.7021 8.19026 46.4087 22.9319 30.0292C38.7654 12.5577 65.5187 1.63795 90.634 0V38.2189C65.5187 45.3167 50.2311 64.9722 50.2311 86.8116H114.657V189.97H0.000488281V99.9152ZM121.209 99.9152C121.209 67.7021 129.399 46.4087 144.141 30.0292C159.974 12.5577 186.727 1.63795 211.843 0V38.2189C186.727 45.3167 171.44 64.9722 171.44 86.8116H235.866V189.97H121.209V99.9152Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_213_37">
            <rect
              width="235.865"
              height="189.97"
              fill="white"
              transform="translate(0.000488281)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      className="w-full"
      viewBox="0 0 236 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_213_39)">
        <path
          d="M235.866 90.0547C235.866 122.268 227.676 143.561 212.934 159.941C197.101 177.412 170.348 188.332 145.232 189.97L145.232 151.751C170.348 144.653 185.635 124.998 185.635 103.158L121.209 103.158L121.209 -4.05412e-05L235.866 -3.05176e-05L235.866 90.0547ZM114.657 90.0547C114.657 122.268 106.467 143.561 91.7257 159.941C75.8921 177.412 49.1389 188.332 24.0236 189.97L24.0236 151.751C49.1389 144.653 64.4265 124.998 64.4265 103.158L0.000236471 103.158L0.00024549 -5.11376e-05L114.657 -4.1114e-05L114.657 90.0547Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_213_39">
          <rect
            width="235.865"
            height="189.97"
            fill="white"
            transform="translate(235.866 189.97) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
